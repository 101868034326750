<!-- 公共的订单表格显示 -->
<template>
  <div class="jepass-already">
    <div
      v-for="(alreadyList, _index) in alreadyBuy"
      :key="_index"
      :id="`remindNumber_${_index}`"
    >
      <el-row
        class="position-relative border-bottom-2-solid-F2F2F2 height-50 line-height-50"
      >
        <div :class="alreadyList.remindNumber && 'fadenumRemind' || ''" />
        <el-col :span="6">
          <div class="font-size-16 color-A9ABA9">
            {{ alreadyList.date }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="font-size-16 color-A9ABA9">
            <text-highlight :queries="keyWordSearch">
              订单号：{{ alreadyList.orderNumber }}
            </text-highlight>
          </div>
        </el-col>
      </el-row>
      <el-row
        class="padding-top-15 padding-left-10 padding-bottom-10 border-bottom-2-solid-F2F2F2 min-height-150"
      >
        <!-- 购买的商品详细信息 -->
        <el-col
          :id="`alreadyTab${_index}Id`"
          :span="12"
          class="height-100-bai"
        >
          <el-row
            style="display: flex;align-items: center;"
            class="border-right-2-solid-F2F2F2 height-100-bai padding-right-15 padding-TB-15"
          >
            <el-col
              :span="4"
              class="border-radius-5 padding-top-10"
            >
              <img
                class="img-65"
                v-lazy="'/jepaas/document/preview?fileKey=' + alreadyList.photo"
              >
            </el-col>
            <el-col :span="20">
              <div
                :class="[alreadyList.Monetary.type == 'YQX' && 'color-A9ABA9', alreadyList.auditState == '05' && 'color-A9ABA9']"
                class="color-3F3F3F font-size-18 font-weight-500 cursor-pointer-hover-F9514F"
                @click="openUrl(alreadyList)"
              >
                <text-highlight :queries="keyWordSearch">
                  {{ alreadyList.detailTitle }}
                </text-highlight>
              </div>
              <span
                v-if="['ZSSJ', 'SYZS'].includes(alreadyList.type)"
                class="font-size-14 color-A9ABA9 margin-top-10"
              >
                可建表数：{{ alreadyList.details.canList }}，
                可建功能数：{{ alreadyList.details.canFunc }}，
                可建流程数：{{ alreadyList.details.canFlow }}，
                使用人数：{{ alreadyList.details.canUser }}，
                服务器数：{{ `${alreadyList.details.canMachine}${alreadyList.credentialNumber && '，'}` }}
              </span>
              <div
                v-if="alreadyList.details.content"
                class="font-size-14 color-A9ABA9 margin-top-10"
              >
                {{ alreadyList.details.content }}
              </div>
              <span
                v-if="['ZSSJ', 'SYZS'].includes(alreadyList.type)"
                class="font-size-14 color-A9ABA9"
              >
                <span v-if="alreadyList.details.topTitle">
                  顶部显示:
                  <text-highlight :queries="keyWordSearch">{{ alreadyList.details.topTitle }}</text-highlight>，
                </span>
                <span v-if="alreadyList.details.topLoading">
                  数据加载:
                  <text-highlight :queries="keyWordSearch">{{ alreadyList.details.topLoading }}</text-highlight>，
                </span>
                <span v-if="alreadyList.details.companyDetails">
                  公司信息:
                  <text-highlight :queries="keyWordSearch">{{ alreadyList.details.companyDetails }}</text-highlight>
                </span>
              </span>
            </el-col>
          </el-row>
        </el-col>
        <el-col
          :span="5"
          class="border-right-2-solid-F2F2F2 moneyDisplay"
          :style="`min-height: ${getElementHeight(`alreadyTab${_index}Id`)}px;`"
        >
          <div
            v-if="alreadyList.Monetary.type == 'ZFCG' && ['ZSSJ', 'SYZS'].includes(alreadyList.type) && !alreadyList.credentialNumber && alreadyList.auditState != '04'"
            class="font-size-18 color-376BD0 text-status-center font-weight-600"
          >
            <span class="color-A9ABA9">审核中</span>
          </div>
          <div
            v-if="alreadyList.zsType == 'GMZS' && alreadyList.Monetary.type == 'ZFCG' && ['ZSSJ', 'SYZS'].includes(alreadyList.type) && alreadyList.credentialNumber && alreadyList.auditState == '04'"
            class="font-size-18 color-376BD0 text-status-center font-weight-600"
          >
            <span class="color-F9524F">证书审核驳回</span>
          </div>
          <div
            v-if="alreadyList.credentialNumber"
            class="font-size-18 color-376BD0 text-status-center font-weight-600"
          >
            <span
              v-if="alreadyList.zsType != 'GMZS' || alreadyList.auditState != '04'"
              :class="[alreadyList.Monetary.type == 'YQX' && 'color-A9ABA9', alreadyList.auditState == '05' && 'color-A9ABA9']"
            >{{ alreadyList.credentialNumber }}</span>
            <div
              v-if="alreadyList.auditState == '02' && alreadyList.Monetary.type == 'DZF'"
              class="text-status-center color-A9ABA9 font-size-18 font-weight-600 margin-top-5"
            >
              {{ alreadyList.zsType == 'SJZS' && '升级证书待支付' || '' }}
            </div>
            <div
              v-if="alreadyList.auditState == '02' && alreadyList.Monetary.type == 'ZFCG'"
              class="text-status-center color-A9ABA9 font-size-18 font-weight-600 margin-top-5"
            >
              {{ alreadyList.zsType == 'XGBQ' && '修改版权审核中' || '' }}
              {{ alreadyList.zsType == 'SJZS' && '升级证书审核中' || '' }}
            </div>
            <div
              v-if="alreadyList.auditState == '03'"
              class="text-status-center color-67C23A font-size-18 font-weight-600 margin-top-5"
            >
              {{ alreadyList.zsType == 'XGBQ' && '修改版权审核通过' || '' }}
              {{ alreadyList.zsType == 'SJZS' && '升级证书审核通过' || '' }}
            </div>
            <div
              v-if="alreadyList.auditState == '04'"
              class="text-status-center color-F9524F font-size-18 font-weight-600 margin-top-5"
            >
              {{ alreadyList.zsType == 'XGBQ' && '修改版权审核驳回' || '' }}
              {{ alreadyList.zsType == 'SJZS' && '升级证书审核驳回' || '' }}
            </div>
            <div
              v-if="alreadyList.auditState == '05'"
              class="text-status-center color-A9ABA9 font-size-18 font-weight-600 margin-top-5"
            >
              {{ alreadyList.zsType == 'XGBQ' && '修改版权审核通过(失效)' || '' }}
              {{ alreadyList.zsType == 'SJZS' && '升级证书审核通过(失效)' || '' }}
            </div>
          </div>
        </el-col>
        <el-col
          :span="3"
          class="border-right-2-solid-F2F2F2 moneyDisplay"
          :style="`min-height: ${getElementHeight(`alreadyTab${_index}Id`)}px;`"
        >
          <div class="text-status-center">
            <!-- 支付的金额 -->
            <div class="font-size-18 color-F9514F font-weight-500">
              <span
                :class="[alreadyList.Monetary.type == 'YQX' && 'color-A9ABA9', alreadyList.auditState == '05' && 'color-A9ABA9']"
              >￥{{ alreadyList.Monetary.number.toFixed(2) }}</span>
            </div>
            <div
              v-if="alreadyList.Monetary.type != 'ZFCG'"
              class="font-size-16 color-3F3F3F margin-top-5"
            >
              <span
                :class="(alreadyList.Monetary.type == 'YQX' || alreadyList.Monetary.type == 'DZF') && 'color-A9ABA9'"
              >{{ alreadyList.Monetary.text }}</span>
            </div>
            <!-- 支付的方式 微信 支付宝 白条 或者 已取消 待支付 -->
            <div
              v-if="alreadyList.payType.code && alreadyList.Monetary.type == 'ZFCG'"
              :class="alreadyList.auditState == '05' && 'color-A9ABA9'"
              class="color-3F3F3F padding-LR-10 padding-TB-5"
            >
              <i
                class="font-size-18 vertical-align-middle margin-right-5"
                :class="[alreadyList.payType.icon, alreadyList.auditState == '05' && 'color-A9ABA9']"
              />
              <span class="font-size-16 vertical-align-middle">{{ alreadyList.payType.text }}</span>
            </div>
          </div>
        </el-col>
        <el-col
          :style="`height: ${getElementHeight(`alreadyTab${_index}Id`)}px;`"
          :span="4"
        >
          <div
            style="display: flex;flex-wrap: wrap"
            class="font-size-16 height-100-bai color-3F3F3F"
          >
            <div
              v-for="(setText, set_) in alreadyList.sets"
              :key="set_"
              style="align-self: center"
              class="width-50-bai"
              :class="(set_+1) % 2 != 0 && 'text-status-center'"
              :style="alreadyList.sets.length == 1 && 'width: 100%'"
              @click="setClick(setText, alreadyList)"
            >
              <span
                v-if="!['payType', 'downLoad'].includes(setText.code)"
                :class="!setText.banClick && 'cursor-pointer-hover-F9514F' || 'color-A9ABA9'"
              >{{ setText.name }}</span>
              <!-- 下载证书的下拉框判断  如果没有关联证书的话就不显示下载证书的下拉 -->
              <span
                v-if="setText.code == 'downLoad' && setText.banClick"
                class="color-A9ABA9"
              >{{ setText.name }}</span>
              <!-- <span v-if="setText.code == 'auditIng'">
                <el-button
                  style="padding: 0"
                  class="font-size-16 color-F9514F"
                  :loading="true"
                  type="text"
                  disabled
                >审核中</el-button>
              </span> -->
              <div v-if="setText.code == 'downLoad' && !setText.banClick">
                <el-dropdown
                  trigger="click"
                  @visible-change="getEncryptSYZS(alreadyList)"
                  @command="downCommand"
                >
                  <div class="text-status-center">
                    <span class="el-dropdown-link font-size-16 cursor-pointer-hover-F9514F">
                      {{ setText.name }}
                      <i class="el-icon-arrow-down el-icon--right" />
                    </span>
                  </div>
                  <!-- alreadyList.allowPayType 允许使用的支付方式 -->
                  <el-dropdown-menu
                    slot="dropdown"
                  >
                    <el-dropdown-item
                      :command="{type: 'developer'}"
                    >
                      <div class="color-3F3F3F">
                        <span class="vertical-align-middle">开发版证书</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item
                      :command="{type: 'serve'}"
                    >
                      <div class="color-3F3F3F">
                        <span class="vertical-align-middle">商业版证书</span>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <!-- 待支付状态下提供支付的方式 -->
              <div v-if="setText.code == 'payType'">
                <div v-if="alreadyList.payLoading">
                  <span>正在支付</span>
                  <i class="jeicon jeicon-loading font-size-16 rotateLinear margin-left-5" />
                </div>
                <el-dropdown
                  v-else
                  trigger="click"
                  @command="payCommand"
                >
                  <div class="text-status-center">
                    <span class="el-dropdown-link font-size-16 cursor-pointer-hover-F9514F">
                      {{ setText.name }}
                      <i class="el-icon-arrow-down el-icon--right" />
                    </span>
                  </div>
                  <!-- alreadyList.allowPayType 允许使用的支付方式 -->
                  <el-dropdown-menu
                    v-if="alreadyList.allowPayType"
                    slot="dropdown"
                  >
                    <el-dropdown-item
                      v-if="alreadyList.allowPayType.includes('wechat') && alreadyList.Monetary.number <= 3000"
                      :command="{type: 'wechat', data: alreadyList}"
                    >
                      <div class="color-3F3F3F">
                        <i class="jeicon jeicon-wechatpay color-24AF41 vertical-align-middle" />
                        <span class="vertical-align-middle">微信支付</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="alreadyList.allowPayType.includes('ali')"
                      :command="{type: 'ali', data: alreadyList}"
                    >
                      <div class="color-3F3F3F">
                        <i class="jeicon jeicon-alipay color-069CE7 vertical-align-middle" />
                        <span class="vertical-align-middle">支付宝支付</span>
                      </div>
                    </el-dropdown-item>
                    <!-- <el-dropdown-item
                      v-if="payTypeButtons.includes('XXZF')"
                      :command="{type: 'other', data: alreadyList}"
                    >
                      <div class="color-3F3F3F">
                        <i class="jeicon jeicon-rmb-on color-FBB11A vertical-align-middle" />
                        <span class="vertical-align-middle">线下支付</span>
                      </div>
                    </el-dropdown-item>-->
                    <el-dropdown-item
                      v-if="alreadyList.allowPayType.includes('BTZF')"
                      :command="{type: 'BTZF', data: alreadyList}"
                    >
                      <div class="color-3F3F3F">
                        <i class="jeicon jeicon-ious-pay color-3F3F3F vertical-align-middle" />
                        <span class="vertical-align-middle">白条支付</span>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div
      class="pageTablePosation text-status-center position-relative margin-top-20 padding-bottom-20"
    >
      <div>
        <el-pagination
          background
          :current-page="pageSetList.page"
          :page-size="pageSetList.pageSize"
          layout="prev, pager, next, jumper, total, slot"
          :total="pageSetList.pageAllTotal"
          @current-change="pageHandleChange"
        >
          <span>
            <i
              class="jeicon jeicon-reduction cursor-pointer-hover-F9514F margin-top-10"
              @click="replaceList(true)"
            />
          </span>
        </el-pagination>
      </div>
    </div>
    <!-- 弹出框 -->
    <div>
      <!-- 使用说明 -->
      <el-dialog
        :visible.sync="instructionsDialog"
        width="40%"
        @open="openSrcoll"
        @close="stopSrcoll"
        custom-class="useExplain"
      >
        <div
          slot="title"
          class="useTitle"
        >
          使用说明
        </div>
        <div
          style="line-height: 2"
          class="color-3F3F3F font-size-16 padding-bottom-30 padding-LR-20 padding-top-10"
        >
          <span v-if="!zqzjInstructions">
            证书分为在线更新和离线更新。当您的平台环境有网络时，可以使用在线更新商业版证书，输入证书号、密码即可获取新的商业版证书， 若您的平台环境没有网络时，
            可以在商城我的已购中，查找到对应的商业证书订单，点击下载离线商业版证书，然后在您的平台中进行 离线导入证书即可更新为最新的证书。 数据加载字样、公司信息字样。
          </span>
          <span v-else>
            增强组件钥匙为离线更新，请您将下载的组件钥匙文件，放在
            您的JEPaaS平台的指定路径下，并重启服务即可激活增强组件功能。平台
            路径为：apache-tomcat-8.5.34\webapps\WebRoot\JE\data\config。
          </span>
        </div>
      </el-dialog>

      <!-- 取消订单弹出框 -->
      <el-dialog
        :visible.sync="removeOrder"
        width="558px"
        :close-on-click-modal="false"
        append-to-body
      >
        <div
          slot="title"
          class="font-size-20 font-weight-600"
        >
          取消订单提示
        </div>
        <div class="tip-word font-size-20 text-status-center">
          您确认取消订单吗？
        </div>
        <div
          slot="footer"
          class="text-status-center margin-bottom-20"
        >
          <el-button
            size="small"
            type="danger"
            class="min-width-120 margin-right-20"
            @click="closerOrderList"
          >
            确 定
          </el-button>
          <el-button
            size="small"
            class="min-width-120"
            @click="removeOrder = false"
          >
            取 消
          </el-button>
        </div>
      </el-dialog>
      <!-- 下载商业证书 -->
      <el-dialog
        :visible.sync="downLoadCertificate"
        :close-on-click-modal="false"
        width="60%"
        @open="openSrcoll"
        @close="stopSrcoll"
      >
        <span
          slot="title"
          class="color-3F3F3F font-size-24 font-weight-500"
        >下载商业版证书</span>
        <div class="padding-left-15 margin-top-10">
          <div class="color-B5B4B4 font-size-18">
            证书号
          </div>
          <div class="margin-top-10 padding-right-15">
            <el-input
              v-model="downLoadText"
              readonly
              class="font-size-18"
              placeholder="请输入内容"
            />
          </div>
          <div class="color-B5B4B4 font-size-18 margin-top-20">
            证书密钥
          </div>
          <div class="margin-top-10 padding-right-15">
            <el-input
              v-model="downTextarea"
              readonly
              class="font-size-18"
              type="textarea"
              :rows="8"
              placeholder="请输入内容"
            />
          </div>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <!-- ' + alreadyList.photo -->
          <div class="clear-float padding-left-15 padding-right-15 margin-top-10">
            <span
              class="float-left font-size-18 color-376BD0 cursor-pointer-hover"
              @click="downLoad_ZS(`/jepaas/document/preview?fileKey=${downPkvalue.value}`, downPkvalue.name)"
            >点击下载离线证书</span>
            <span
              class="float-right cursor-pointer-hover-F9514F font-size-16 color-3F3F3F"
              @click="instructionsDialog = true"
            >使用说明</span>
          </div>
        </span>
      </el-dialog>
      <el-dialog
        :visible.sync="downLoadDeveloper"
        :close-on-click-modal="false"
        width="60%"
        @open="openSrcoll"
        @close="stopSrcoll"
      >
        <span
          slot="title"
          class="color-3F3F3F font-size-24 font-weight-500"
        >下载开发版证书</span>
        <div class="padding-left-15 margin-top-10">
          <div class="color-B5B4B4 font-size-18">
            证书号
          </div>
          <div class="margin-top-10 padding-right-15">
            <el-input
              v-model="downLoadTextDev"
              readonly
              class="font-size-18"
              placeholder="请输入内容"
            />
          </div>
          <div class="color-B5B4B4 font-size-18 margin-top-20">
            证书密钥
          </div>
          <div class="margin-top-10 padding-right-15">
            <el-input
              v-model="downTextareaDev"
              readonly
              class="font-size-18"
              type="textarea"
              :rows="8"
              placeholder="请输入内容"
            />
          </div>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <!-- ' + alreadyList.photo -->
          <div class="clear-float padding-left-15 padding-right-15 margin-top-10">
            <span
              class="float-left font-size-18 color-376BD0 cursor-pointer-hover"
              @click="downLoad_ZS(`/jepaas/document/preview?fileKey=${downPkvalueDev.value}`, downPkvalueDev.name)"
            >点击下载离线证书</span>
            <span
              class="float-right cursor-pointer-hover-F9514F font-size-16 color-3F3F3F"
              @click="instructionsDialog = true"
            >使用说明</span>
          </div>
        </span>
      </el-dialog>
      <el-dialog
        :visible.sync="downLoadKey"
        :close-on-click-modal="false"
        width="60%"
        @open="openSrcoll"
        @close="stopSrcoll"
      >
        <span
          slot="title"
          class="color-3F3F3F font-size-24 font-weight-500"
        >下载钥匙</span>
        <div class="padding-left-15 margin-top-10">
          <div class="color-B5B4B4 font-size-18 margin-top-20">
            钥匙密钥
          </div>
          <div class="margin-top-10 padding-right-15">
            <el-input
              v-model="downTextareaKey"
              readonly
              class="font-size-18"
              type="textarea"
              :rows="8"
              placeholder="请输入内容"
            />
          </div>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <!-- ' + alreadyList.photo -->
          <div class="clear-float padding-left-15 padding-right-15 margin-top-10">
            <span
              class="float-left font-size-18 color-376BD0 cursor-pointer-hover"
              @click="downLoad_ZS(`/jepaas/document/preview?fileKey=${downPkvalueKey.value}`, downPkvalueKey.name)"
            >点击下载离线钥匙</span>
            <span
              class="float-right cursor-pointer-hover-F9514F font-size-16 color-3F3F3F"
              @click="instructionsDialog = true"
            >使用说明</span>
          </div>
        </span>
      </el-dialog>
      <el-dialog
        :visible.sync="rejectInfoDialog"
        :close-on-click-modal="false"
        width="35%"
        @open="openSrcoll"
        @close="stopSrcoll"
      >
        <span
          slot="title"
          class="color-3F3F3F font-size-24 font-weight-500"
        >审核驳回原因</span>
        <div class="padding-left-15 margin-top-10">
          <div class="color-3F3F3F font-size-18 margin-top-20">
            &emsp;{{ rejectInfo }}
          </div>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
        />
      </el-dialog>
      <!-- 修改版权信息 -->
      <el-dialog
        :visible.sync="editCopyRight"
        :close-on-click-modal="false"
        width="40%"
        @open="openSrcoll"
        @close="stopSrcoll"
      >
        <span
          slot="title"
          class="color-3F3F3F font-size-24 font-weight-500"
        >{{ auditIng ?'版权审核中' :'修改版权信息' }}</span>
        <div class="padding-left-15 margin-top-10">
          <div class="color-B5B4B4 font-size-18">
            顶部显示字样
          </div>
          <div class="margin-top-10 padding-right-15">
            <el-input
              maxlength="50"
              @blur="validateCopyright(certificateForm.topTitle, 'topTitle')"
              v-model="certificateForm.topTitle"
              :disabled="auditIng"
              class="font-size-18"
              placeholder="技术支持：凯特伟业"
            />
          </div>
          <div class="color-B5B4B4 font-size-18 margin-top-20">
            顶部显示字样连接
          </div>
          <div class="margin-top-10 padding-right-15">
            <el-input
              @blur="validateCopyright(certificateForm.topHref, 'topHref')"
              v-model="certificateForm.topHref"
              :disabled="auditIng"
              class="font-size-18"
              :rows="5"
              placeholder="http://www.jepaas.com"
            />
          </div>
          <div class="color-B5B4B4 font-size-18 margin-top-20">
            数据加载字样
          </div>
          <div class="margin-top-10 padding-right-15">
            <el-input
              maxlength="50"
              @blur="validateCopyright(certificateForm.topLoading, 'topLoading')"
              v-model="certificateForm.topLoading"
              :disabled="auditIng"
              class="font-size-18"
              :rows="5"
              placeholder="凯特云办公系统加载中..."
            />
          </div>
          <div class="color-B5B4B4 font-size-18 margin-top-20">
            公司信息字样
          </div>
          <div class="margin-top-10 padding-right-15">
            <el-input
              @blur="validateCopyright(certificateForm.companyDetails, 'companyDetails')"
              v-model="certificateForm.companyDetails"
              :disabled="auditIng"
              maxlength="50"
              class="font-size-18"
              :rows="5"
              placeholder="北京凯特伟业提供技术支持 电话：400-0999-235"
            />
          </div>
        </div>
        <div
          class="background-FFF7E9 padding-TB-10 padding-left-40 padding-right-40 color-F84E4E margin-top-20"
        >
          <div>【温馨提示】需15分钟待审核；</div>
          <div class="margin-top-5">
            确认后，一旦申请成功，离线的需要重新更新，更新后会生效新的版权信息。
          </div>
        </div>
        <span slot="footer">
          <div class="text-status-center margin-top-20">
            <el-button
              class="background-F6474E color-FFFFFF margin-right-40 width-120"
              @click="copyrightSub"
            >确 定</el-button>
            <el-button
              class="width-120"
              @click="editCopyRight = false"
            >取 消</el-button>
          </div>
        </span>
      </el-dialog>
      <!-- 白条支付 -->
      <el-dialog
        :visible.sync="IOUVisible"
        width="40%"
        append-to-body
      >
        <div
          slot="title"
          class="font-size-20 font-weight-600"
        >
          白条支付提示
        </div>
        <div class="text-status-center">
          <img
            src="../../../../detail/assets/images/pay/payTip.png"
            alt=""
          >
        </div>
        <div class="color-3F3F3F font-size-16 padding-left-40 padding-right-40 margin-top-20">
          <div>您未开通白条资格，需具备如下部分条件后，联系销售人员开通白条权限：</div>
          <div class="margin-top-40">
            1、您符合JEPaaS平台优质客户资质。
          </div>
          <div class="margin-top-10">
            2、您在JEPaaS平台消费已超过50万元。
          </div>
          <div class="margin-top-10">
            3、您使用JEPaaS平台累计时间超过2年。
          </div>
          <div class="margin-top-40">
            如有任何疑问，请联系销售进行询问及开通白条资格。
          </div>
          <div class="margin-top-20">
            <i class="jeicon jeicon-the-phone vertical-align-middle font-size-16 color-FBB11A margin-right-5" />
            <span class="margin-left-5 vertical-align-middle">石经理：</span>
            <span class="color-FFA200 vertical-align-middle">15010612217</span>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        :visible.sync="payVisible"
        width="558px"
        :close-on-click-modal="false"
        append-to-body
        @open="openSrcoll"
        @close="stopSrcoll"
        @closed="payByBTZF(payDetails, 'close')"
      >
        <div
          slot="title"
          class="font-size-20 font-weight-600"
        >
          白条支付提示
        </div>
        <div class="tip-img text-status-center">
          <img
            src="../../../../detail/assets/images/pay/btPay.png"
            alt
          >
        </div>
        <div class="tip-word text-status-center">
          您确认使用白条支付方式购买JEPaaS平台产品？
        </div>
        <div
          slot="footer"
          class="text-status-center"
        >
          <el-button
            size="small"
            type="danger"
            :loading="JEpayLoading"
            class="min-width-120 margin-right-20"
            @click="payByBTZF(payDetails)"
          >
            确 定
          </el-button>
          <el-button
            size="small"
            class="min-width-120"
            @click="payByBTZF(payDetails, 'close')"
          >
            取 消
          </el-button>
        </div>
      </el-dialog>
      <!-- 微信支付 -->
      <el-dialog
        :visible.sync="qrCodeVisible"
        width="558px"
        append-to-body
        custom-class="qrcode-dialog"
        @open="openSrcoll"
        @close="stopSrcoll"
      >
        <div
          slot="title"
          class="font-size-20 font-weight-600"
        >
          微信支付
        </div>
        <div class="tip-img">
          <img
            :src="'/je/jepaas/document/preview?fileKey=' + qrcodeImg"
            alt
          >
        </div>
      </el-dialog>
      <payDialog
        ref="payDialog"
        :loading="loading"
        :product-type-pay="productTypePay"
        @openPhoneDialog="openPhone"
        v-on="$listeners"
      />
      <PhoneDialog
        ref="phoneDialog"
      />
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { POST_ORDER_REMOVE, POST_PLACE_PAY } from '@/pages/index/actions/api';
import { getIOU, getEncrypt, addOrder } from '@/pages/detail/actions/actions';
import { getEditCopyright, getCheckAuditStatus, getRejectInfo } from '@/pages/index/actions/actions';
import {
  errorCallback, isObjectValueEqual, clearSpace, checkCharacter, checkNumber,
} from '@/util/util';
import payDialog from '../../../../detail/components/payDialog';
import PhoneDialog from '../../../../detail/components/phone';
// import payVideo from '../../../../detail/components/payVideo';
import payTypeButton from '../../../../detail/components/js/mixins';

export default {
  components: {
    payDialog,
    PhoneDialog,
  },
  mixins: [payTypeButton],
  props: {
    pageSetList: {
      type: Object,
      default: () => ({}),
    },
    // 关键词搜索后传递的参数  用来显示部分字体高亮
    keyWordSearch: {
      type: String,
      default: () => '',
    },
    alreadyBuy: {
      type: Array,
      default: () => [],
    },
  },
  // 声明依赖  让子组件paydialog 获取这个类型
  provide() {
    return {
      productType: this.editDeatils.type || '',
    };
  },
  data() {
    return {
      rejectInfoDialog: false, // 驳回原因弹出框
      rejectInfo: '', // 审核驳回的驳回原因
      zqzjInstructions: false, // 增强组件使用说明
      payTypeButtons: [], // 当前的支付方式所拥有的
      editDeatils: {}, // 当前选中的列表的数据
      removeOrder: false, // 取消订单的弹出框
      loading: false, // 支付弹出的弹出框
      auditIng: false, // 版权审核中是否开启  false为关闭
      instructionsDialog: false, // 使用说明弹出框
      productTypePay: '',
      payDetails: {}, // 当前选中的订单详情
      payNow: false, // 立即购买的弹出框
      certificateForm: {
        topTitle: '',
        topHref: '',
        topLoading: '',
        companyDetails: '',
      }, // 现在证书的携带数据和更改后的数据
      isCopyright: false, // 修改版权的正则判断是否通过
      editCopyRight: false, // 修改版权信息
      downLoadCertificate: false, // 下载服务器证书弹出框
      downLoadText: '', // 下载证书号
      downTextarea: '', // 证书密码
      downPkvalue: {
        name: '',
        value: '',
      }, // 下载文件的名字和pkvalue
      downLoadKey: false, // 下载钥匙
      downTextareaKey: '', // 下载要是的密钥
      downPkvalueKey: {
        name: '',
        value: '',
      }, // 下载文件的名字和pkvalue
      downLoadDeveloper: false, // 下载开发者证书
      downLoadTextDev: '', // 下载证书号
      downTextareaDev: '', // 证书密码
      downPkvalueDev: {
        name: '',
        value: '',
      }, // 下载文件的名字和pkvalue
      JEpayLoading: false, // 白条支付弹窗确定按钮loading
      editDeatilsJson: {}, // 取消引用过关系的数据对象
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  methods: {
    getElementHeight(id) {
      if (!document.getElementById(`${id}`)) return;
      return document.getElementById(`${id}`).clientHeight;
    },
    // 下载相关的证书
    downCommand(type) {
      switch (type.type) {
        case 'serve':
          // 服务器证书
          this.downLoadCertificate = true;
          break;
        case 'developer':
          // 开发者证书
          this.downLoadDeveloper = true;
          break;
      }
    },
    // 修改版权 --- 接口对接
    subCopyRight(params) {
      const details = this.editDeatils;
      const addOrderParams = {
        productCode: details.urlCode.toLocaleUpperCase(),
        type: 'SYZS',
        zsType: 'XGBQ',
        zsCode: params.copyrightBh,
        payType: details.payType.code,
        userCount: JSON.stringify({ count: details.details.canUser, code: 'SYYHS', name: '使用用户数' }),
        flowCount: JSON.stringify({ count: details.details.canFlow, code: 'KJLCS', name: '可建流程表数' }),
        funcCount: JSON.stringify({ count: details.details.canFunc, code: 'KJGNS', name: '可建功能数' }),
        machineCount: JSON.stringify({ count: details.details.canMachine, code: 'SYJQS', name: '服务器数' }),
        tableCount: JSON.stringify({ count: details.details.canList, code: 'KJBS', name: '可建表数' }),
        topLink: JSON.stringify({ value: params.topLink || '', code: 'DBLJ', name: '顶部链接' }),
        topWord: JSON.stringify({ value: params.topWord, code: 'DBXSZ', name: '顶部显示字' }),
        companyInfo: JSON.stringify({ value: params.companyInfo, code: 'GSXXZY', name: '公司信息字样' }),
        dataWord: JSON.stringify({ value: params.dataWord, code: 'SJJZZY', name: '数据加载字样' }),
      }; // 格式修改版权先下单的数据
      if (details.auditState == '04' && this.editDeatils.zsType != 'XGBQ') {
        // 第一次购买证书审核驳回可以直接修改订单不需要重新下单
        const jsonData = {
          SJJZZY: params.dataWord,
          GSXXZY: params.companyInfo,
          DBXSZ: params.topWord,
          DBLJ: params.topLink,
        };
        params.jsonData = JSON.stringify(jsonData);
        getEditCopyright(params).then((res) => {
          if (res.success) {
            this.stopSrcoll(); // 关闭滚动条的监听
            this.editCopyRight = false;
            this.replaceList(true); // 修改版权信息 -- 刷新列表数据
          } else {
            console.log(`修改版权接口错误信息: ${res.message || '没有错误信息~'}`);
          }
        });
      } else {
        addOrder(addOrderParams).then(() => {
        }).then(() => {
          getEditCopyright(params).then((res) => {
            if (res.success) {
              this.stopSrcoll(); // 关闭滚动条的监听
              this.editCopyRight = false;
              this.replaceList(true); // 修改版权信息 -- 刷新列表数据
            } else {
              console.log(`修改版权接口错误信息: ${res.message || '没有错误信息~'}`);
            }
          });
        });
      }
    },
    /**
     * @name: 点击操作中的按钮
     * @Descripttion:
     * @param {set} 当前点击的按钮的参数
     * @param {details} 当前点击列表的这一条订单额详细数据
     * @returns:
     */
    setClick(set, details) {
      if (set.banClick) return; // 如果存在禁止点击方法就返回不执行下面的判断
      this.editDeatils = details; // 当前选中的列表的数据
      this.editDeatilsJson = JSON.parse(JSON.stringify(details)); // 取消引用过关系的数据对象  用作更改对比
      switch (set.code) {
        case 'downLoadKey': // 下载证书的弹出框
          this.getEncryptSYZS(details);
          this.downLoadKey = true;
          break;
        case 'subReject': // 驳回原因弹出框
          getRejectInfo({ orderCode: details.orderNumber }).then((res) => {
            if (res.success) {
              this.rejectInfoDialog = true;
              this.rejectInfo = res.obj.BQXGJL_BHYY || '暂无驳回原因！';
            } else {
              console.log(`驳回原因接口错误信息: ${res.message}`);
            }
          });
          break;
        case 'editCopyRight': // 修改版权信息
        // 如果是商业证书审核驳回的状态就不需要进行校验了 所以单独判断拉出来
          if (details.auditState == '04' && this.editDeatils.zsType != 'XGBQ') {
            this.certificateForm = {
              topTitle: details.details.topTitle || '',
              topHref: details.details.topHref || '',
              topLoading: details.details.topLoading || '',
              companyDetails: details.details.companyDetails || '',
            }; // 将点击列表的值进行赋值
            this.editCopyRight = true;
            return;
          }
          getCheckAuditStatus({ copyrightBh: details.credentialNumber }).then((check) => {
            if (!check.success) {
              this.$emit('remindNumber', { number: details.credentialNumber });
              this.alreadyBuy.forEach((item, _item) => {
                if (item.remindNumber) {
                  const btn = document.getElementById(`remindNumber_${_item}`);
                  const x = btn.offsetTop;
                  // // debugger;
                  // document.documentElement.scrollTop = x;
                  // 阻力，数值越大，滑动越慢
                  const drag = 10;
                  // 滑动到顶部
                  const scrollToTop = () => {
                    // 距离顶部的距离
                    const gap = document.documentElement.scrollTop || document.body.scrollTop;
                    if (gap > x) {
                      window.requestAnimationFrame(scrollToTop);
                      window.scrollTo(0, gap - gap / drag);
                    }
                  };
                  scrollToTop();
                }
              });
              this.$message.warning('已存在版权审核中的订单');
              return true;
            }
          }).then((code) => {
            if (code) return; // 如果已存在版权审核中的订单，不下向下执行代码
            this.certificateForm = {
              topTitle: details.details.topTitle || '',
              topHref: details.details.topHref || '',
              topLoading: details.details.topLoading || '',
              companyDetails: details.details.companyDetails || '',
            }; // 将点击列表的值进行赋值
            this.editCopyRight = true;
          });
          break;
        case 'buyAgain': // 重新购买
          // 该订单的商业证书购买策略详情进行保存，跳转页面进行回显
          sessionStorage.setItem('syzsBuyType', JSON.stringify(details.details));
          setTimeout(() => {
            sessionStorage.removeItem('syzsBuyType'); // 跳转页面后清空掉本页面的缓存数据 防止污染商业证书的默认选中事件
          }, 1000);
          window.open(`shop/${details.urlCode}.html`);

          break;
        case 'upload': // 升级商业证书
          getCheckAuditStatus({ copyrightBh: details.credentialNumber }).then((check) => {
            if (!check.success) {
              this.$message.error('已存在升级证书审核中的订单');
              return true;
            }
          }).then((code) => {
            if (code) return;
            // 该订单的商业证书购买策略详情进行保存，跳转页面进行回显
            details.upload = true; // 用来区分是重新购买商业证书还是升级商业证书
            sessionStorage.setItem('syzsBuyType', JSON.stringify(details));
            setTimeout(() => {
              sessionStorage.removeItem('syzsBuyType'); // 跳转页面后清空掉本页面的缓存数据 防止污染商业证书的默认选中事件
            }, 1000);
            window.open(`shop/${details.urlCode}.html`);
          });
          break;
        case 'playVideo': // 播放视频
          window.open(`/shop/video/${details.urlCode}`);
          break;
        case 'showDetail': // 使用详情
          this.$store.commit('getOrderInfo', details);
          this.$router.push({
            path: '/certificateShow',
          });
          break;
        case 'closeOrder': // 取消订单
          this.removeOrder = true;
          // this.closerOrderList(details.orderId || ''); // 取消订单的调用方法
          break;
        case 'instructions': // 使用说明
          details.type == 'ZQZJ' && (this.zqzjInstructions = true);
          this.instructionsDialog = true;
          break;
        case 'payType': // 立即付款
          this.payDetails = details;
          break;
      }
    },
    // 获取商业证书的密文
    getEncryptSYZS(details) {
      if (this.downLoadText && details.credentialNumber == this.downLoadText) return; // 因为element提供的方法 点击显示收起都会调用该方法  所以如果之前下载证书已经有值的话就不再显示
      const param = {
        copyrightBh: details.credentialNumber,
        pluginCode: details.pluginCode,
        orderCode: details.orderNumber,
      };
      if (!details.pluginCode) delete param.pluginCode;
      getEncrypt(param).then((res) => {
        if (!res.success) return this.$message.error('证书获取失败');
        const data = res.obj;
        data.forEach((text) => {
          const val = text.values;
          const arr = val.ZSGLB_ZSDZ.split('*');
          const name = arr[0];
          const value = arr[1];
          if (val.ZSGLB_ZSLX_CODE == 'SYZS') {
            this.downLoadText = val.ZSGLB_ZSWYM || ''; // 获取证书号
            this.downTextarea = val.ZSGLB_SY || ''; // 证书密文
            this.downPkvalue.name = name; // 下载证书的名字
            this.downPkvalue.value = value || ''; // 证书下载key
          } else if (val.ZSGLB_ZSLX_CODE == 'KFZS') {
            this.downLoadTextDev = val.ZSGLB_ZSWYM || ''; // 获取证书号
            this.downTextareaDev = val.ZSGLB_SY || ''; // 证书密文
            this.downPkvalueDev.name = name; // 下载证书的名字
            this.downPkvalueDev.value = value || ''; // 证书下载key
          } else {
            this.downTextareaKey = val.ZSGLB_SY || ''; // 证书密文
            this.downPkvalueKey.name = name; // 下载证书的名字
            this.downPkvalueKey.value = value || ''; // 证书下载key
          }
        });
      });
    },
    // 点击下载离线证书
    downLoad_ZS(url, name) {
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = url;
      tempLink.download = `${name}`;
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    },
    // 分页的点击事件
    pageHandleChange(page) {
      this.$emit('pageChange', page);
    },
    // 点击取消订单接口调用
    closerOrderList() {
      const Id = this.editDeatils.orderId;
      this.$ajax({
        url: POST_ORDER_REMOVE,
        data: {
          orderId: Id || '',
        },
      }).then(() => {
        this.removeOrder = false;
        this.replaceList(true); // 取消成功之后刷新接口
      });
    },
    // 点击刷新列表按钮
    replaceList(status) {
      this.$emit('refreshList', status);
    },
    // 版权审核的change 正则验证
    validateCopyright(value, key) {
      const keyArr = {
        topTitle: '顶部显示字样不能为空',
        topLoading: '数据加载字样不能为空',
        companyDetails: '公司信息字样不能为空',
      };
      if (!value && key != 'topHref') {
        this.isCopyright = false;
        return this.$message.warning(keyArr[key]);
      }
      if (checkCharacter(clearSpace(value))) {
        this.isCopyright = false;
        return this.$message.warning('您输入了非法字符，请重新输入！');
      }
      if (checkNumber(clearSpace(value))) {
        this.isCopyright = false;
        return this.$message.warning('不能输入纯数字！');
      }
      this.isCopyright = true;
    },
    // 版权审核点击提交
    copyrightSub() {
      const keyArr = ['topTitle', 'topHref', 'companyDetails', 'topLoading'];
      const objOldForm = {
        topTitle: this.editDeatilsJson.details.topTitle,
        topHref: this.editDeatilsJson.details.topHref,
        topLoading: this.editDeatilsJson.details.topLoading,
        companyDetails: this.editDeatilsJson.details.companyDetails,
      };
      const isTrue = isObjectValueEqual(this.certificateForm, objOldForm); // 判断对象是否相等 true 为相等
      if (isTrue) {
        this.$message.error('版权信息没有任何更改');
        return;
      }
      keyArr.forEach((key) => {
        this.validateCopyright(this.certificateForm[key], key);
      });
      if (!this.isCopyright) return;
      const params = {
        topWord: this.certificateForm.topTitle,
        topLink: this.certificateForm.topHref,
        companyInfo: this.certificateForm.companyDetails,
        dataWord: this.certificateForm.topLoading,
        orderCode: this.editDeatils.orderNumber,
        copyrightBh: this.editDeatils.credentialNumber,
        type: this.editDeatils.auditState == '04' && this.editDeatils.zsType != 'XGBQ' && 'XGDD' || 'XGBQ',
      };
      this.subCopyRight(params);
    },
    // 点击标题进行连接跳转
    openUrl(url) {
      if (!url) {
        this.$message.error('不存在url');
        return;
      }
      window.open(`shop/${url.urlCode}.html`);
    },
    // 生成当前时间
    getTimeUtil() {
      Date.prototype.Format = function (fmt) {
        // author: meizz
        const o = {
          'M+': this.getMonth() + 1, // 月份
          'd+': this.getDate(), // 日
          'h+': this.getHours(), // 小时
          'm+': this.getMinutes(), // 分
          's+': this.getSeconds(), // 秒
          'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
          S: this.getMilliseconds(), // 毫秒
        };
        if (/(y+)/.test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            (`${this.getFullYear()}`).substr(4 - RegExp.$1.length),
          );
        }
        for (const k in o) {
          if (new RegExp(`(${k})`).test(fmt)) {
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : (`00${o[k]}`).substr((`${o[k]}`).length),
            );
          }
        }
        return fmt;
      };
      const time = new Date().Format('yyyy-MM-dd hh:mm:ss');
      return time;
    },
    // 接口SPZF*  VIP* DS*等等信息过滤
    transactionTypePro(type) {
      let productType = 'SPZF*';
      switch (type) {
        case 'VIP':
          productType = 'VIP*';
          break;
        case 'DS':
          productType = 'DS*';
          break;
        case 'ZYZL':
          productType = 'ZYZL*';
          break;
        case 'SJZS':
          productType = 'SJZS*';
          break;
      }
      return productType;
    },
    // 白条的确认支付按钮
    paySubLoans(details) {
      // 获取当前的白条额度
      getIOU().then((result) => {
        if (result.success) {
          // 拿系统余额跟当前需支付总价格对比：如果足够支付，就下单。不足以支付，做出提示！
          if (result.obj && Number(result.obj.BTGL_KYED) && (Number(result.obj.BTGL_KYED) > Number(details.data.Monetary.number))) {
            this.payVisible = true;
            return true;
          }
          this.$message({
            type: 'warning',
            message: '您当前额度不足以支付此次订单金额，请联系销售人员协商处理！',
            duration: 5000,
          });
          details.data.payLoading = false;
          return false;
        }
        // 无白条权限 提示
        if (result.errorCode == '100002') {
          this.IOUVisible = true;
        }
        return false;
      });
    },
    // 白条的确认支付按钮后的接口调用
    payByBTZF(details, status) {
      if (status) {
        details.data.payLoading = false;
        this.payVisible = false; // 确认弹框消失
      }
      !status && this.$ajax({
        url: POST_PLACE_PAY,
        data: {
          zsCode: details.data.zsType == 'SJZS' && details.data.credentialNumber,
          orderId: details.data.orderId, // 订单的ID
          productType: this.formatProduct(details.data.type), // 订单是证书 还是增强组件 网盘之类的专属字段key值
          type: details.type, // 支付类型
          transactionType: this.transactionTypePro(details.data.zsType || details.data.transactionType || details.data.type),
        },
      })
        .then((res) => {
          const result = res.data;
          if (result.success) {
            // 白条支付
            details.data.payLoading = false; // 操作按钮的支付loading
            this.payVisible = false; // 确认弹框消失
            this.JEpayLoading = false; // 关闭按钮的加载中
            this.$emit('refreshList', true);
            this.$message.success('支付成功');
          }
        });
    },
    // 立即支付功能
    payCommand(details) {
      this.productTypePay = details.data.type;
      this.payDetails = details; // 当前点击列表的这一条订单额详细数据
      // if(details.type == 'wechat' && details.data.Monetary.number > 3000) {

      // }
      details.data.payLoading = true; // 操作中的支付loading
      details.type == 'BTZF' && this.paySubLoans(details);
      details.type != 'BTZF' && this.$ajax({
        url: POST_PLACE_PAY,
        data: {
          zsCode: details.data.zsType == 'SJZS' && details.data.credentialNumber,
          orderId: details.data.orderId || '', // 订单的ID
          productType: this.formatProduct(details.data.type), // 订单是证书 还是增强组件 网盘之类的专属字段key值
          type: details.type, // 支付类型
          transactionType: this.transactionTypePro(details.data.zsType || details.data.transactionType || details.data.type),
        },
      })
        .then((res) => {
          const result = res.data;
          if (result.success) {
            if (details.type == 'wechat') {
              this.$refs.payDialog.qrcodeImg = result.obj.data;
              this.$refs.payDialog.title = '微信支付提示';
              this.$refs.payDialog.amountPayable = details.data.Monetary.number;
              this.$refs.payDialog.payTime = result.obj.date; // 下单时间
              this.$refs.payDialog.payComputeTime(); // 开始倒计时
              this.$refs.payDialog.orderId = details.data.orderId;
              this.$refs.payDialog.orderCode = details.data.orderNumber; // 订单编号
              setTimeout(() => {
                this.$refs.payDialog.payVisible = true;
              }, 500);
            } else if (details.type == 'ali') {
              const newTab = window.open();
              this.alipayForm = result.obj.data;
              // newTab.document.charset = 'UTF-8';
              const div = document.createElement('div');
              div.innerHTML = result.obj.data;
              newTab.document.body.appendChild(div);
              newTab.document.forms.alipaysubmit.submit();
              this.$refs.payDialog.title = '支付宝支付提示';
              this.$refs.payDialog.amountPayable = details.data.Monetary.number;
              this.$refs.payDialog.payTime = this.getTimeUtil();
              this.$refs.payDialog.orderId = details.data.orderId;
              this.$refs.payDialog.orderCode = details.data.orderNumber; // 订单编号
              setTimeout(() => {
                this.$refs.payDialog.payVisible = true;
              }, 1000);
            }
            details.data.payLoading = false;
          } else {
            errorCallback(result.errorCode);
            details.data.payLoading = false;
          }
        })
        .then((res) => {
          const result = res.data;
          if (result.success) {
            if (details.type == 'wechat') {
              this.$refs.payDialog.qrcodeImg = result.obj.data;
              this.$refs.payDialog.title = '微信支付提示';
              this.$refs.payDialog.amountPayable = details.data.Monetary.number;
              this.$refs.payDialog.payTime = result.obj.date; // 下单时间
              this.$refs.payDialog.payComputeTime(); // 开始倒计时
              this.$refs.payDialog.orderId = details.data.orderId;
              this.$refs.payDialog.orderCode = details.data.orderNumber; // 订单编号
              setTimeout(() => {
                this.$refs.payDialog.payVisible = true;
              }, 500);
            } else if (details.type == 'ali') {
              const newTab = window.open();
              // newTab.document.charset = 'UTF-8';
              this.alipayForm = result.obj.data;
              const div = document.createElement('div');
              div.innerHTML = result.obj.data;
              newTab.document.body.appendChild(div);
              newTab.document.forms.alipaysubmit.submit();
              this.$refs.payDialog.title = '支付宝支付提示';
              this.$refs.payDialog.amountPayable = details.data.Monetary.number;
              this.$refs.payDialog.payTime = this.getTimeUtil();
              this.$refs.payDialog.orderId = details.data.orderId;
              this.$refs.payDialog.orderCode = details.data.orderNumber; // 订单编号
              setTimeout(() => {
                this.$refs.payDialog.payVisible = true;
              }, 1000);
            }
            details.data.payLoading = false;
          } else {
            errorCallback(result.errorCode);
            details.data.payLoading = false;
          }
        })
        .catch(() => {});
    },
    // 产品类型 type 转 name
    formatProduct(data) {
      let productType = '商业证书';
      switch (data) {
        case 'SYZS':
          productType = '商业证书';
          break;
        case 'ZQZJ':
          productType = '增强组件';
          break;
        case 'ZYZL':
          productType = '资源资料';
          break;
        case 'VIP':
          productType = 'VIP服务';
          break;
        case 'RGFW':
          productType = '人工服务';
          break;
        case 'HYCP':
          productType = '行业产品';
          break;
        default:
          break;
      }
      return productType;
    },
    // 开启滚动
    openSrcoll() {
      const mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', mo, false); // 禁止页面滑动
    },
    stopSrcoll() {
      const mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ''; // 出现滚动条
      document.removeEventListener('touchmove', mo, false);
    },
    // 打开联系电话弹窗
    openPhone() {
      this.$refs.phoneDialog.phoneDialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../style/shop/util';
</style>
<style lang="less">
.jepass-already {
  .fadenumRemind {
    position: absolute;
    left: -15px;
    top: 0;
    width: calc(100% + 30px);
    height: 50px;
    z-index: 2;
    animation:fadenum 2s ease;
  }
  @keyframes fadenum{ /*设置内容由显示变为隐藏*/

    0%{opacity: 0.2; background: #F9524F;}

    100%{opacity: 1;background: transparent;}

  }
  .pageTablePosation {
    position: absolute;
    bottom: 10px;
    width: 1210px;
  }
  .pageRefresh {
    position: absolute;
    left: 61%;
    top: -3px;
  }
  .moneyDisplay {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-pagination.is-background .el-pager .active {
    background-color: #f6474e !important;
  }
  .pay-tips {
    .el-dialog__body {
      padding: 0 20px;
    }
    .tip-img {
      font-size: 0;
      text-align: center;
    }
    .tip-word {
      font-size: 14px;
      font-weight: 400;
      color: rgba(63, 63, 63, 1);
      text-align: center;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .tip-btword {
      font-size: 14px;
      font-weight: 400;
      color: rgba(63, 63, 63, 1);
      margin-top: 10px;
      pre {
        line-height: 30px;
        padding: 0 20px;
        i {
          color: #fc6251;
          margin-right: 10px;
        }
        .phoneNum {
          color: #faa71f;
        }
      }
    }
    .el-dialog__footer {
      text-align: center;
      .el-button--small {
        min-width: 120px;
      }
      .el-button + .el-button {
        margin-left: 40px;
      }
    }
  }
  .el-dialog {
    box-shadow: none;
    border-radius: 8px;
    .el-dialog__body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0);
    }
  }
  // 无限旋转
  .rotateLinear {
    transition: 0.5s;
    transform-origin: 30px 30px;
    animation: rotate 1s linear infinite; /*开始动画后无限循环，用来控制rotate*/
  }
}
.useExplain {
  width: 640px !important;
  border-radius: 20px !important;
  padding: 0!important;
  .el-dialog__header {
    padding: 0!important;
  }
  .useTitle {
    display: block;
    padding: 20px;
    box-sizing: border-box;
    color: #ffffff;
    height: 180px;
    background: url('../../../../detail/assets/images/zs/service.png') !important;
    background-size: 100% auto !important;
    background-repeat: no-repeat !important;
  }
  .el-dialog__headerbtn .el-icon-close{
    font-size: 25px;
    color: #ffffff!important;
  }
  .el-dialog__title {
    font-size: 22px !important;
    font-weight: 600 !important;
  }
  .el-dialog__body {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    color: rgba(63, 63, 63, 1);
    padding-top: 10px;
    padding-bottom: 100px;
  }
}
</style>
