/**
 * @Author : MingYao Zhang
 * @Date : 2020/3/20 15:51
 * @Version : 1.0
 * @Last Modifined by : MingYao Zhang
 * @Last Modifined time : 2020/3/20 15:51
 * @Description
 * */
// 每个支付按钮的混入方法
const payTypeButton = {
  data() {
    return {
      loading: false,
      hasIOUlimit: true, // 是否有白条额度
      IOUVisible: false, // 白条提示
      payVisible: false, // 支付提示
      qrCodeVisible: false, // 微信二维码弹窗
      qrcodeImg: '',
      alipayVisible: false,
      alipayForm: '',
    };
  },
  methods: {
    // 点击支付按钮  处理逻辑
    doPay(type) {
      switch (type) {
        // 微信支付
        case 'wechat':
          this.startPay(type);
          break;
        // 支付宝支付
        case 'ali':
          this.startPay(type);
          break;
        // 线下支付
        case 'other':
          this.startPay(type);
          break;
        // 白条支付
        case 'loans':
          if (this.hasIOUlimit) {
            this.payVisible = true;
          } else {
            this.IOUVisible = true;
          }
          break;
        default:
          break;
      }
    },
    // 白条支付弹窗  点击确定
    payByIOU() {
      this.payVisible = false;
      this.startPay();
    },
    // 开始支付
    startPay(type) {
      // 先校验必填项
      // 商业证书的校验
      if (this.productType == 'SYZS') {
        if (!this.formInput.topFont) {
          this.$message({
            type: 'warning',
            message: '顶部显示字样不能为空',
          });
          return false;
        }
        if (!this.formInput.topLink) {
          this.$message({
            type: 'warning',
            message: '顶部显示字样链接不能为空',
          });
          return false;
        }
        if (!this.formInput.loadFont) {
          this.$message({
            type: 'warning',
            message: '数据加载字样不能为空',
          });
          return false;
        }
        if (!this.formInput.comFont) {
          this.$message({
            type: 'warning',
            message: '公司信息字样不能为空',
          });
          return false;
        }
      }

      // 白条支付
      if (!type) {
        // 处理白条支付逻辑 TODO 接口暂未出
      } else {
        // 线下支付
        if (type == 'other') {
          this.$emit('openPhoneDialog');
          return;
        }
        // 下单之后 开始微信或者支付宝支付
        this.addOrder(type, (data) => {
          this.$ajax({
            url: '/jepaas/order/placePay',
            data: {
              orderId: data.obj,
              productType: this.productType,
              type,
            },
          }).then((result) => {
            if (result.data.success) {
              if (type == 'wechat') {
                this.qrcodeImg = result.data.obj;
                this.qrCodeVisible = true;
                // this.payCallback(type);
              } else if (type == 'ali') {
                this.alipayForm = result.data.obj;
                const div = document.createElement('divform');
                div.innerHTML = result.data.obj;
                document.body.appendChild(div);
                document.forms[1].submit();
                // this.payCallback(type);
              }
            } else {
              this.$message({
                type: 'error',
                message: result.data.message,
              });
            }
          });
        });
      }
    },
    // 支付前下单
    addOrder(type, callback) {
      this.$ajax({
        url: '/jepaas/order/addOrder',
        data: this.getParams(type),
      }).then((result) => {
        if (result.data.success) {
          return callback(result.data);
        }
        this.$message({
          type: 'error',
          message: result.data.message,
        });
      });
    },
    // 处理参数
    getParams(type) {
      const params = {
        type: this.productType,
        payType: type,
        userCount: this.formData.userCount || 0,
        flowCount: this.formData.flowCount || 0,
        funcCount: this.formData.funcCount || 0,
        machineCount: this.formData.machineCount || 0,
        tableCount: this.formData.tableCount || 0,
        topLink: this.formInput.topLink || '',
        topWord: this.formInput.topFont || '',
        companyInfo: this.formInput.comFont || '',
        dataWord: this.formInput.loadFont || '',
      };
      // 不是商业证书  多两个参数  页面url和规则id
      if (this.productType != 'SYZS') {
        params.productUrl = '';
        params.configurationId = this.formData.configurationId;
      }
      return params;
    },
    // 支付完回调
    payCallback(type) {
      const dsq = setInterval(() => {
        this.$ajax({
          url: '/jepaas/order/checkOrderStatus',
        }).then((result) => {
          if (result.data.success) {
            clearInterval(dsq);
            if (type == 'wechat') {
              this.qrCodeVisible = false;
            } else if (type == 'ali') {
              this.alipayVisible = false;
            }
            this.$refs.payDialog.payVisible = true;
          }
        });
      }, 60000);
    },
  },
};
export default payTypeButton;
