<template>
  <div class="Jepaas-detail background-F5F5F5">
    <el-backtop />
    <div>
      <div class="box_top">
        <Header />
      </div>
      <div class="je-detail-content je-shop-YGDD">
        <div class="font-size-30 color-3F3F3F font-weight-600">
          我的订单
        </div>
        <el-row
          class="margin-top-40"
          type="flex"
          justify="space-between"
        >
          <el-col :span="4">
            <!-- 快速查询的菜单 -->
            <div class="padding-left-20">
              <el-dropdown
                trigger="click"
                @command="pitchOnSelect"
              >
                <span
                  class="color-F84E4E font-size-24 border-bottom-2-solid-F84E4E font-weight-500 cursor-pointer-hover display-block padding-bottom-5"
                >
                  {{ thisMenuSelect.DICTIONARYITEM_ITEMNAME }}
                  <i
                    class="jeicon jeicon-sort-small-down font-size-20 margin-left-10"
                  />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(selectObj, _select) in menuSelect"
                    :key="_select"
                    :command="selectObj"
                  >
                    <span>
                      {{ selectObj.DICTIONARYITEM_ITEMNAME }}
                    </span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-col>
          <el-col
            :span="7"
            class="elemnt-input-YGDD"
          >
            <span class="font-size-16 color-3F3F3F vertical-align-middle">关键词：</span>
            <el-input
              v-model="keyWordSearch"
              clearable
              class="border-none vertical-align-middle"
              placeholder="商品名称/订单号"
              @clear="refreshList(true)"
            >
              <i
                slot="prefix"
                :class="keyWordSearch && 'cursor-pointer-hover-F9514F' || ''"
                class="el-input__icon el-icon-search"
                @click="getOrderList"
              />
            </el-input>
          </el-col>
          <el-col
            :span="7"
            :offset="1"
            class="elemnt-input-YGDD"
          >
            <span class="font-size-16 color-3F3F3F vertical-align-middle">证书号：</span>
            <el-select
              v-model="orderNumbersPitch"
              placeholder="请选择证书号"
              style="width: 80%"
              popper-class="ZSH-select-number"
              clearable
              @change="orderNumbersChange"
            >
              <el-option
                v-for="(item, _index) in orderNumbers"
                :key="_index"
                :label="item.values.DDGL_GLZS"
                :value="item.values.DDGL_GLZS"
              />
            </el-select>
          </el-col>
          <el-col
            :span="4"
            class="elemnt-input-YGDD"
          >
            <span class="font-size-16 color-3F3F3F vertical-align-middle">订单状态：</span>
            <el-select
              v-model="orderStatusPitch.DICTIONARYITEM_ITEMCODE"
              placeholder="请选择状态"
              style="width: 60%"
              @change="orderStatusChange"
            >
              <el-option
                v-for="item in orderStatus"
                :key="item.DICTIONARYITEM_ITEMCODE"
                :label="item.DICTIONARYITEM_ITEMNAME"
                :value="item.DICTIONARYITEM_ITEMCODE"
              />
            </el-select>
          </el-col>
        </el-row>
        <!-- 自定义的一些表格 -->
        <!-- 表格的头部标题 -->
        <div class="margin-top-35 background-FFFFFF padding-TB-10 padding-LR-20 border-radius-5">
          <el-row class="font-weight-500">
            <el-col :span="6">
              <div class="font-size-20 color-3F3F3F">
                订单日期
              </div>
            </el-col>
            <el-col :span="6">
              <div class="font-size-20 color-3F3F3F">
                订单详情
              </div>
            </el-col>
            <el-col :span="5">
              <div class="font-size-20 color-3F3F3F text-status-center">
                关联的商业证书
              </div>
            </el-col>
            <el-col :span="3">
              <div class="font-size-20 color-3F3F3F text-status-center">
                金额
              </div>
            </el-col>
            <el-col :span="4">
              <div class="font-size-20 color-3F3F3F text-status-center">
                操作
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 表格的内容 -->
        <div
          :style="alreadyBuy.length >= 0 && '' || 'display:table; width: 100%'"
          class="alreadyTable margin-top-20 background-FFFFFF padding-LR-15 padding-bottom-15 border-radius-5 position-relative"
        >
          <Loading :loadingData="{isShow: orderTableLoading}" />
          <!-- 日期和订单号 -->
          <tableUtil
            ref="tableUtilChild"
            v-if="tableRefresh && alreadyBuy.length > 0"
            :key-word-search="keyWordSearch"
            :already-buy="alreadyBuy"
            :page-set-list="pageSetList"
            @editCopyRight="editCopyRight"
            @refreshList="refreshList"
            @pageChange="pageChange"
            @loadingTabTrue="loadingTabTrue"
            @loadingTabFalse="loadingTabFalse"
            @remindNumber="remindNumber"
          />
          <div
            v-else
            style="display:table-cell;vertical-align:middle;text-align: center"
          >
            <img
              src="../../../../detail/assets/images/alreadyIndent/nullData.png"
              alt
            >
            <div class="color-BEBEBE">
              暂无数据
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
import {
  POST_ORDER_LIST,
  POST_ORDER_SYZS,
  POST_ORDER_TYPE,
} from '@/pages/index/actions/api';
import { getToken } from '@/util/auth';
import { getStorage } from '@/util/util';
import { isArray } from '../../../../../util/util';
import tableUtil from '../components/tableUtil'; // 公共的表格
// import { alreadyBuy } from './testData'; // 已购订单的测试数据

export default {
  name: 'YGDD',
  components: {
    Header,
    tableUtil,
  },
  data() {
    return {
      tableRefresh: true, // 手动刷新订单的重新渲染 用v-if判断
      pageSetList: {
        pageAllTotal: 0,
        page: 1, // 当前页数
        pageSize: 20, // 每页显示条数
      },
      orderTableLoading: false,
      orderStatusPitch: {
        DICTIONARYITEM_ITEMNAME: '全部',
        DICTIONARYITEM_ITEMCODE: '',
      }, // 订单状态的当前选中
      orderStatus: [
        {
          DICTIONARYITEM_ITEMNAME: '全部',
          DICTIONARYITEM_ITEMCODE: '',
        },
      ],
      orderNumbersPitch: '', // 当前选中的证书号
      orderNumbers: [], // 证书订单号下拉
      alreadyBuy: [], // 测试table数据
      thisMenuSelect: {
        DICTIONARYITEM_ITEMNAME: '全部',
        DICTIONARYITEM_ITEMCODE: '',
      },
      isLoginOrRegister: '商城',
      keyWordSearch: '', // 关键词搜索
      menuSelect: [
        {
          DICTIONARYITEM_ITEMNAME: '全部',
          DICTIONARYITEM_ITEMCODE: '',
        },
      ], // 快速筛选的下拉菜单选择
      isRoutePage: false, // 是否走路由
      activeName: 'CPXQ',
      persons: [],
      hasCopyright: true, // 用户是否有证书
    };
  },
  watch: {
    // $route(nv) {
    //   if (!nv.name) {
    //     this.isRoutePage = false;
    //   } else {
    //     this.isRoutePage = true;
    //   }
    // },
  },
  mounted() {
    // 在页面加载时读取localStorage里的状态信息
    if (getToken()) {
      if (getStorage('local', 'jepaasStore')) {
        this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(getStorage('local', 'jepaasStore'))));
      }
    }
    // 进入订单页面判断是否有升级证书的缓存的数据，一定要先删除掉 不然点击证书进入的页面就会变成升级证书
    if (sessionStorage.getItem('syzsBuyType')) {
      sessionStorage.removeItem('syzsBuyType');
    }
  },
  created() {
    const loginStatus = this.isLoaginUser(); // 判断当前用户是否登录了
    if (!loginStatus) return; // 如果用户没登录不执行下方的方法
    this.getUserCertificate(); // 获取用户的商业证书号
    this.getLoadProducType(); // 获取商品类型字典接口
    this.getOrderStatus(); // 获取订单状态的字典接口
    this.getOrderList(true); // 默认获取订单列表的数据
    this.enterSearch(); // 回车快速查询监听
  },
  methods: {
    // 修改版权或者升级证书的提示背景
    remindNumber(data) {
      this.alreadyBuy = this.alreadyBuy.map((list) => {
        if (list.credentialNumber == data.number && list.auditState == '02') {
          list.remindNumber = true;
        }
        return list;
      });
      setTimeout(() => {
        this.alreadyBuy = this.alreadyBuy.map((list) => {
          if (list.credentialNumber == data.number && list.auditState == '02') {
            list.remindNumber = false;
          }
          return list;
        });
      }, 2000);
    },
    // 回车快速查询
    enterSearch() {
      const lett = this;
      document.onkeydown = () => {
        const key = window.event.keyCode;
        if (key == 13) {
          lett.getOrderList(true);
        }
      };
    },
    // 获取订单状态的编码
    getOrderStatus() {
      this.$ajax({
        url: POST_ORDER_TYPE,
        data: {
          dictionaryCode: 'JEPAAS_ORDER_STATUS',
        },
      }).then((type) => {
        const data = type.data.obj;
        data.forEach((item) => {
          this.orderStatus.push(item);
        });
      });
    },
    // 获取商品类型字典接口
    getLoadProducType() {
      this.$ajax({
        url: POST_ORDER_TYPE,
        data: {
          dictionaryCode: 'JEPAAS_SPSSFL',
        },
      }).then((type) => {
        const data = type.data.obj;
        data.forEach((item) => {
          this.menuSelect.push(item);
        });
      });
    },
    // 刷新方法
    refreshList(status) {
      this.getOrderList(status);
    },
    // 区域开始加载
    loadingTabTrue() {
      this.orderTableLoading = true;
    },
    // 区域加载取消
    loadingTabFalse() {
      this.orderTableLoading = false;
    },
    // 分页的执行方法
    pageChange(page) {
      this.pageSetList.page = page || 1; // 当前的页数
      this.getOrderList(true); // 重新获取订单列表的数据
    },
    // 修改版权信息 -- 为审核中
    editCopyRight(orderId) {
      this.alreadyBuy = this.alreadyBuy.filter((item) => {
        if (item.orderId == orderId) {
          item.sets = [
            {
              name: '审核中',
              code: 'auditIng',
            },
          ];
        }
        return item;
      });
    },
    // 获取我的订单的信息 status : 是否执行区域刷新的样式
    getOrderList(status) {
      this.tableRefresh = false; // 手动刷新订单的重新渲染
      this.orderTableLoading = status || false;
      this.$ajax({
        url: POST_ORDER_LIST,
        data: {
          zsh: this.orderNumbersPitch || '',
          gjc: this.keyWordSearch || '',
          type: this.thisMenuSelect.DICTIONARYITEM_ITEMCODE || '',
          orderStatus: this.orderStatusPitch.DICTIONARYITEM_ITEMCODE || '',
          pageSize: this.pageSetList.pageSize, // 当前每页显示的条数
          currPage: this.pageSetList.page, // 当前的页数
        },
      })
        .then((order) => {
          this.orderTableLoading = false;
          this.settingOrderData(order.data.obj || order.data.rows);
          this.pageSetList.pageAllTotal = order.data.totalCount; // 获取总共的我的订单的列表条数+
          this.tableRefresh = true; // 手动刷新订单的重新渲染
        })
        .catch(() => {
          this.orderTableLoading = false;
        });
    },
    // 获取用户订单状态下的各种状态下的数据
    orderStatusChange(status) {
      this.orderStatusPitch.DICTIONARYITEM_ITEMCODE = status;
      this.getOrderList(true);
    },
    // 获取用户下的商业证书号
    getUserCertificate() {
      this.$ajax({
        url: POST_ORDER_SYZS,
        data: {},
      }).then((order) => {
        const data = order.data.obj;
        if (order.data.success && isArray(data)) {
          this.orderNumbers = data.filter(item => item.values.DDGL_GLZS);
        }
      });
    },
    // 快速查询之商业证书查询
    orderNumbersChange(val) {
      this.orderNumbersPitch = val;
      this.getOrderList(true);
    },
    // 快速查询的菜单选择
    pitchOnSelect(obj) {
      // 如果当前选的和之前选中的结果一致  将不再执行查询的方法
      if (this.thisMenuSelect == obj) return;
      // 只有是新的参数才会进行查询筛选
      obj && (this.thisMenuSelect = obj);
      this.getOrderList(true); // 重新执行调用列表刷新方法
    },
    // 电话客服弹出
    openPhone() {
      this.persons = [
        { name: '云经理', phone: '18610941078', order: 1 },
        { name: '赵经理', phone: '17610358522', order: 2 },
        { name: '石经理', phone: '15010612217', order: 3 },
      ];
      // 随机排序
      this.persons.sort(() => Math.random() - 0.5);
      this.$refs.phoneDialog.phoneDialogVisible = true;
    },
    // 判断当前用户是否登录了
    isLoaginUser() {
      if (!localStorage.getItem('userInfo')) {
        this.$router.push({
          path: '/login',
        });
        return false;
      }
      return true;
    },
    // 接口数据对照格式处理
    settingOrderData(data) {
      const testD = [];
      data.forEach((order) => {
        const orderList = {
          payLoading: false, // 当前一旦点击支付后，支付状态转换为转圈的状态
          photo: order.photo.split('*')[1],
          zsType: order.zsType || '', // 证书修改版权的返回状态区分
          transactionType: `${order.cpzl}` || '',
          pluginCode: order.pluginCode || '', // 下载要是需要的参数Code
          auditState: order.auditState || '', // 修改版权的审核状态 02 审核中 03 审核通过 04 审核驳回
          urlCode: order.productCode && order.productCode.toLocaleLowerCase() || '', // 商品的专属code
          url: order.url, // 点击订单标题需要跳转的地方
          type: order.type, // 商业证书的编号等
          date: order.orderDate || '无下单日期', // 下单日期
          orderId: order.orderId, // 订单的唯一ID
          orderNumber: order.orderBh || '订单无', // 订单编号
          detailTitle: `【${order.spmc || '无'}】${order.productName || ''}`, // 详情的标题
          details: this.orderDetailsFormat(order.type, order) || '', // 订单上面的详情
          credentialNumber: order.glzs || '', // 关联证书编号
          Monetary: {
            number: order.cjjg, // 价格
            type: order.orderStatus, // 支付的状态
            text:
              (order.orderStatus != 'ZFCG'
                && { DZF: '待支付' }[order.orderStatus])
              || { YQX: '已取消' }[order.orderStatus]
              || { ZFSB: '支付失败' }[order.orderStatus]
              || '新状态',
          },
          allowPayType: (order.yxzffs && order.yxzffs.split(',')) || [], // 允许使用的支付方式
          payType: {
            code: order.payType,
            icon:
              {
                ali: 'jeicon jeicon-alipay color-069CE7',
                wechat: 'jeicon jeicon-wechatpay color-24AF41',
                XXZF: 'jeicon jeicon-rmb-on color-FBB11A',
                BTZF: 'jeicon jeicon-ious-pay color-3F3F3F',
              }[order.payType] || '',
            text:
              {
                ali: '支付宝支付', wechat: '微信支付', XXZF: '线下支付', BTZF: '白条支付',
              }[
                order.payType
              ] || '',
          }, // 支付成功的支付方式
          sets: this.orderSetFormat(order.orderStatus, order.type, order.glzs, order.auditState), // 操作
        };
        testD.push(orderList);
      });
      this.alreadyBuy = testD;
      // this.alreadyBuy
    },
    // 接口格式 --- 标题下方的详细内容
    orderDetailsFormat(type, datas) {
      let obj = {};
      const data = datas.pzmx;
      if (!data) return obj;
      if (type == 'SYZS') {
        obj = {
          canList:
            (data.find(MX => MX.code == 'KJBS')
              && data.find(MX => MX.code == 'KJBS').count)
            || 0, // 可建表数
          canFunc:
            (data.find(MX => MX.code == 'KJGNS')
              && data.find(MX => MX.code == 'KJGNS').count)
            || 0, // 可建功能
          canFlow:
            (data.find(MX => MX.code == 'KJLCS')
              && data.find(MX => MX.code == 'KJLCS').count)
            || 0, // 可建流程
          canUser:
            (data.find(MX => MX.code == 'SYYHS')
              && data.find(MX => MX.code == 'SYYHS').count)
            || 0, // 可使用人数
          canMachine:
            (data.find(MX => MX.code == 'SYJQS')
              && data.find(MX => MX.code == 'SYJQS').count)
            || 0, // 可建机器数
          topTitle:
            (data.find(MX => MX.code == 'DBXSZ')
              && data.find(MX => MX.code == 'DBXSZ').count)
            || '', // 顶部样式
          topHref:
            (data.find(MX => MX.code == 'DBLJ')
              && data.find(MX => MX.code == 'DBLJ').count)
            || '', // 顶部链接
          topLoading:
            (data.find(MX => MX.code == 'SJJZZY')
              && data.find(MX => MX.code == 'SJJZZY').count)
            || '', // 数据加载字样
          companyDetails:
            (data.find(MX => MX.code == 'GSXXZY')
              && data.find(MX => MX.code == 'GSXXZY').count)
            || '', // 公司信息字样
        };
      } else if (type == 'RGFW') {
        obj = {
          content: `单价: ${datas.orderDj}，数量：${datas.orderCount} `,
        };
        // 如果是VIP用户就显示
        if (datas.cpzl == 'VIP' && datas.pzmx) {
          obj = {
            content: datas.pzmx[0].name,
          };
        }
      } else {
        obj = {
          content: data[0].descriptionInfo || data[0].description,
        };
      }
      return obj;
    },
    /**
     * @name: 接口格式 -- 操作按钮的过滤
     * @Descripttion: 根据参数支付状态 和关联证书等进行过滤按钮权限  banClick是否允许点击
     * @param {type} 购买商品的编号：SYZS SJZS ZQZJ 等等
     * @param {status} 支付的状态 ZFCG ZFSB DZF 等等
     * @param {number} 关联证书编号
     * @returns:
     */

    // 接口格式 --- 操作功能显示
    orderSetFormat(status, type, number, auditState) {
      let setObj = [
        {
          name: '使用说明',
          code: 'instructions',
        },
        {
          name: '下载证书',
          code: 'downLoad',
          banClick: !number || (auditState == '02'), // 02 修改版权审核中
        },
        {
          name: '使用详情',
          code: 'showDetail',
          banClick: !number || (auditState == '02'),
        },
        {
          name: '升级证书',
          code: 'upload',
          banClick: !number || (auditState == '02'),
        },
        {
          name: '修改版权',
          code: 'editCopyRight',
          banClick: !number || (auditState == '02'),
        },
      ];
      if (auditState == '04') {
        setObj = [
          {
            name: '使用说明',
            code: 'instructions',
          },
          {
            name: '驳回原因',
            code: 'subReject',
          },
          {
            name: '修改订单',
            code: 'editCopyRight',
          },
        ];
      }
      if (auditState == '05') {
        setObj = [
          {
            name: '使用说明',
            code: 'instructions',
          },
        ];
      }
      if (status == 'DZF') {
        setObj = [
          {
            name: '取消订单',
            code: 'closeOrder',
          },
          {
            name: '立即支付',
            code: 'payType',
          },
        ];
      }
      if (status == 'ZFSB') {
        setObj = [
          {
            name: '取消订单',
            code: 'closeOrder',
          },
          {
            name: '重新支付',
            code: 'payType',
          },
        ];
      }
      if (status == 'YQX') {
        setObj = [
          {
            name: '重新购买',
            code: 'buyAgain',
          },
        ];
      }
      if (status == 'ZFCG') {
        switch (type) {
          case 'SYZS':
            break;
          case 'ZQZJ':
            setObj = [
              {
                name: '下载钥匙',
                code: 'downLoadKey',
              },
              {
                name: '使用说明',
                code: 'instructions',
              },
            ];
            break;
          case 'RGFW':
            setObj = [];
            break;
          case 'ZYZL':
            setObj = [
              {
                name: '观看视频',
                code: 'playVideo',
              },
            ];
            break;
        }
      }
      return setObj;
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../style/shop/util';
</style>
<style lang="less">
// 我的订单中的证书号的下拉菜单
.ZSH-select-number {
  min-width: 250px !important;
}
::-webkit-scrollbar {
  display: none;
}
// 高亮的样式显示更改
@import 'style/default';
.Jepaas-detail {
  min-height: 100vh;
  position: relative;
  width: 100%;
  .text__highlight {
    color: red !important;
    background: none !important;
  }
  .alreadyTable {
    position: relative;
    min-height: 600px;
    box-sizing: border-box;
    padding-bottom: 100px;
  }
  .el-backtop {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 4px;
    width: 52px;
    height: 52px;
    right: 2% !important;
    bottom: 43px !important;
    font-size: 24px;
    color: #ff3041;
    &:hover {
      background: #ff3041;
      > i {
        color: #ffffff;
      }
    }
  }
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .je-detail-content {
    margin: 0 auto;
    padding: 20px 0;
    width: 1250px;
    overflow: hidden;
    .elemnt-input-YGDD .el-input__inner {
      border: none;
      border-radius: 20px;
    }
    .title {
      height: 42px;
      font-size: 30px;
      font-weight: 600;
      color: rgba(63, 63, 63, 1);
      line-height: 42px;
    }
    .question {
      height: 70px;
      background: rgba(255, 242, 240, 1);
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      font-weight: 500;
      .el-button {
        font-size: 26px;
        margin-left: 10px;
      }
    }
    .explains {
      margin-top: 30px;
      .el-tabs__item {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(63, 63, 63, 1);
        height: initial;
        line-height: normal;
      }
      .el-tabs__header {
        margin-bottom: 30px;
      }
      .el-tabs__active-bar {
        background-color: #f84e4e;
      }
    }
  }
}
</style>
